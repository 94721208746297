import React, { useContext, useState } from "react";
import { CookieContext } from "../LateralFlag";
import {
  StyledForm,
  StyledFormBlock,
  StyledFormInput,
  StyledFormLabel,
} from "../../../commons/Form";
import useNewsletter from "../../../hooks/useNewsletter";
import {
  StyledButton,
  StyledParagraph,
  StyledSpacer,
  StyledTitle,
} from "../../globalstyle";
import { StyledLateralTextContainer } from "./style";
import useNewsletterListId from "../../../hooks/useNewsletterListId";
import { DataContext } from "../../../contexts/DataContext";

const InputMode = ({
  title, inputLabel, paragraph, pdfUrl, submitLabel, 
}) => {
  const { setCookie } = useContext(CookieContext);
  const { languages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const [formData, setFormData] = useState({});
  const { addToNewsLetter } = useNewsletter();
  const { getListId } = useNewsletterListId();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("qui")

    const response = await addToNewsLetter({
      data: {
        lang: currentLanguageCode,
        email: formData.email,
        list_id: getListId(),
      },
    });

    if (response.status === 200) {
      window.open(pdfUrl, "_blank");
      setCookie(true);
    }
  };

  return (
    <StyledLateralTextContainer>
      <StyledTitle
        mini
        color="white"
        font="sans"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <StyledSpacer size={1} />
      <StyledParagraph
        mini
        color="grayDark"
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
      <StyledSpacer size={2} />
      <StyledForm onSubmit={handleSubmit}>
        <StyledFormBlock>
          <StyledFormInput
            isYellow
            id="input"
            height="20px"
            width="95%"
            type="email"
            required
            name="email"
            onInput={(e) => {
              setFormData({
                ...formData,
                email: e.currentTarget.value, 
              });
            }}
          />
          <StyledFormLabel
            top="15px"
            htmlFor="input"
            isHidden={formData.email && formData.email.length > 0}
          >
            {inputLabel}
          </StyledFormLabel>
        </StyledFormBlock>
        <StyledSpacer size={2} />
        <StyledButton negative type="submit">
          {submitLabel}
        </StyledButton>
      </StyledForm>
    </StyledLateralTextContainer>
  );
};

export default InputMode;
