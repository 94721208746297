import React, { useCallback, useState } from "react";
import { Colors } from "../../commons/Theme";
import useCookies from "../../hooks/useCookies";
import useTimeoutCallback from "../../hooks/useTimeoutCallback.js";
import Icon, { icons } from "../Icon/Icon";
import InputMode from "./InputMode";
import ShareMode from "./ShareMode";
import SimpleMode from "./SimpleMode";
import ScrollMode from "./ScrollMode";
import { StyledLateralContainer, StyledLateralContainer2 } from "./style.jsx";

const ContentComponents = {
  simple: SimpleMode,
  share: ShareMode,
  input: InputMode,
  scroll: ScrollMode,
};

export const CookieContext = React.createContext();

/**
 * @typedef {{component: string, props: {object}}} Content
 * @param {{content: Content}} param0
 * @returns
 */

const DEFAULT_TIMEOUT_SECONDS = 10;
const LateralFlag = ({
  iconName,
  positionY,
  zindex,
  autoSlide = false,
  timeout = DEFAULT_TIMEOUT_SECONDS,
  content,
  cookieName,
  _pulsing = false,
  disabled = false,
}) => {
  const [openFlag, setOpenFlag] = useState(false);
  const [cookie, setCookie] = useCookies(cookieName);
  const [userInteraction, setUserInteraction] = useState(!!cookie);
  const [pulsing, setPulsing] = useState(_pulsing);
  const TIMEOUT_IN_MILLISECONDS = timeout * 1000;

  useTimeoutCallback(
    useCallback(() => setOpenFlag(true), []),
    {
      disabled: userInteraction || !autoSlide,
      timeout: TIMEOUT_IN_MILLISECONDS,
    },
  );

  const handleClick = () => {
    setOpenFlag((prev) => !prev);
    setUserInteraction(true);
    setPulsing(false);
  };
  const ContentComponent = ContentComponents[content.component];

  if (disabled) {
    return;
  }

  return (
    <CookieContext.Provider value={{
      cookie,
      setCookie,
    }}>
      <StyledLateralContainer
        color={Colors.greenClear}
        zindex={zindex}
        positionY={positionY}
        isOpen={openFlag}
        pulsing={pulsing}
        onClick={handleClick}
      >
        <Icon icon={icons[iconName]} color={Colors.white} />
      </StyledLateralContainer>
      <StyledLateralContainer2
        color={Colors.greenClear}
        isOpen={openFlag}
        positionY={positionY}
        zindex={zindex}
      >
        <ContentComponent {...content.props} />
      </StyledLateralContainer2>
    </CookieContext.Provider>
  );
};

export default LateralFlag;
