import { useLocation } from "@reach/router";
import React, { useContext, useState } from "react";
import { FacebookShareButton } from "react-share";
import { CookieContext } from "../LateralFlag";
import { StyledParagraph, StyledSpacer, StyledTitle } from "../../globalstyle";
import Link from "../../Link/Link";
import {
  DiscountCode,
  FbShareImage,
  ShareContainer,
  StyledLateralTextContainer,
} from "./style";
import { DataContext } from "../../../contexts/DataContext";

/**
 *
 * @param {{to: string; title: string; paragraph: string}} Props
 */

const TIMEOUT_MS = 10000;
const ShareMode = ({
  title,
  urlToShare,
  textToShare,
  discountCode,
  paragraph,
  redirectTo,
}) => {
  const location = useLocation();
  const { cookie, setCookie } = useContext(CookieContext);
  const { i18nLink } = useContext(DataContext);
  const [showDiscountCode, setShowDiscountCode] = useState(!!cookie);
  const discountContent = redirectTo ? (
    <StyledTitle font="sans" color="black">
      <Link to={`${i18nLink(redirectTo)}?coupon=${discountCode}`}>{discountCode}</Link>
    </StyledTitle>
  ) : (
    <StyledTitle font="sans">{discountCode}</StyledTitle>
  );
  const handleShare = () => {
    setTimeout(() => {
      setCookie(true);
      setShowDiscountCode(true);
    }, TIMEOUT_MS);
  };

  return (
    <StyledLateralTextContainer>
      <StyledTitle
        mini
        color="white"
        font="sans"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <StyledSpacer size={1} />
      <StyledParagraph
        mini
        color="grayDark"
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
      <StyledSpacer size={2} />
      {showDiscountCode && discountCode ? (
        <DiscountCode>{discountContent}</DiscountCode>
      ) : (
        <ShareContainer>
          <FacebookShareButton
            url={urlToShare ?? location.href}
            quote={textToShare}
            onClick={handleShare}
          >
            <FbShareImage
              src="/2020/svg/icons/fbshare.svg"
              alt="Facebook Share"
            />
          </FacebookShareButton>
        </ShareContainer>
      )}
    </StyledLateralTextContainer>
  );
};

export default ShareMode;
